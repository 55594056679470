import moment from 'moment'
import { useState, useEffect } from 'react'
import axios from 'axios'

export const formatDate = date => {
    return moment(date).format('Do MMMM, YYYY')
}

export const isActiveLink = (location, url) => {
    const { pathname } = location

    let _url = url.replace('/','')

    const _pathname = pathname.replace('/','')
    const isActive = _url && _pathname && _pathname.indexOf(_url) !== -1

    return isActive || (!_pathname && !_url)
}

export const nl2br = str => {
    if (typeof str === 'undefined' || str === null) {
        return ''
    }

    const breakTag = '<br />'
    
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
}

export const stripHtml = str => {
    return str.replace(/<[^>]*>?/gm, '')
}

export const isMobile = typeof window !== 'undefined' ? window.innerWidth <= 500 : false
export const isMobileMenu = typeof window !== 'undefined' ? window.innerWidth <= 1024 : false

export const emailIsValid = email => {
	var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase())
}

export const useIsMobile = (size = 500) => {
    const _window = typeof window !== 'undefined' ? window : null

    const [_isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            if(_window){
                setIsMobile(_window.screen.width <= size)
            }
        },5)
    },[_window])

    return _isMobile
}

export const getReadLength = text => {
    const regex = /(<([^>]+)>)/ig
    const wordsWithoutHtml = text.replace(regex, "")

    const wordCount = wordsWithoutHtml.split(' ').length

    const minutes = wordCount/250

    return Math.round(minutes)+' minute'
}

export const getEvents = () => {
    const formData = new FormData()

    formData.append('action', 'get_events')

    return axios.post(`https://playgroup-at-home.yourcreative.com.au/wp-admin/admin-ajax.php`, formData)
}