import React, { useContext } from "react"
import { MainContext } from "context/context"
import Link from 'components/globals/link'
import { Link as ScrollLink } from "react-scroll"

export default ({
    onClick,
    open,
}) => {

	const {
		options,
    } = useContext(MainContext)

    return (
        <div className={`mobile-menu ${ open ? 'open' : ''}`} onClick={onClick}>
            <menu onClick={e => e.stopPropagation()}>
                <ul className='menu-item-options'>
                    { options.links.map((menuItem, index) => {
                        const {
                            link
                        } = menuItem

                        return (
                            <li key={index}>
                                {link.url.includes('http') ?
                                <Link 
                                    to={ link.url }
                                >
                                    { link.title }
                                </Link>
                                :
                                <ScrollLink
                                        key={ index }
                                        to={ link.url }
                                        spy={ true }
                                        smooth={ true }
                                        offset={ -100 }
                                        duration={ 1000 }
                                        style={{ cursor: `pointer` }}
                                        onClick={onClick}
                                    >
                                        {link.title}
                                </ScrollLink>
                                }
                            </li>
                        )
                    })}
                </ul>
            </menu>
        </div>
    )
}