import React, { useState, useEffect, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { getEvents } from 'utils'

export const MainContext = React.createContext()

const Provider = ({
	children
}) => {
	const componentRefs = useRef([])

	const [modalData, setModalData] = useState(false)
	const [isHeaderInverted, setIsHeaderInverted] = useState(false)
	const [hasScrolled, setHasScrolled] = useState(false)

	const [isLoadingEvents, setIsLoadingEvents] = useState(true)
	const [groupedEventData, setGroupedEventData] = useState({})
	const invertedHeaderSections = useRef([])

	const isHeaderInvertedRef = useRef(isHeaderInverted)
	isHeaderInvertedRef.current = isHeaderInverted

	const hasScrolledRef = useRef(hasScrolled)
	hasScrolledRef.current = hasScrolled

	const _window = typeof window !== 'undefined' ? window : null

	const addInvertedHeaderSection = ref => {
		const { current } = invertedHeaderSections

		if (current.indexOf(ref) === -1) {
			current.push(ref)
		}

		invertedHeaderSections.current = current

		checkInvertedHeaderOffsets()
	}

	useEffect(() => {
		let scrollHandler = null

		if (_window) {
			scrollHandler = _window.addEventListener('scroll', checkInvertedHeaderOffsets)
		}

		getEvents().then(({data}) => {
			setIsLoadingEvents(false)

			const _groupedEventData = {}

			data.forEach(event => {
				const { series_id } = event

				if(!_groupedEventData[series_id]){
					_groupedEventData[series_id] = [event]
				}else{
					_groupedEventData[series_id].push(event)
				}
			})

			setGroupedEventData(_groupedEventData)
		})

		return () => {
			if (scrollHandler) {
				scrollHandler.remove()
			}
		}
	}, [_window])

	const checkInvertedHeaderOffsets = pos => {
		const delta = (pos && pos.delta) ? pos.delta.y : 0

		const invertRefs = invertedHeaderSections.current
		let _isHeaderInverted = false

		const hasScrolledOffset = 100

		if (_window) {
			const scrollOffset = delta

			if (scrollOffset > hasScrolledOffset && !hasScrolledRef.current) {
				setHasScrolled(true)
			} else if (scrollOffset <= hasScrolledOffset && hasScrolledRef.current) {
				setHasScrolled(false)
			}
		}

		invertRefs.forEach(ref => {
			const offsets = ref.getBoundingClientRect()

			const isOverSection = offsets.top < 120 && (offsets.top + offsets.height) > 120

			if (isOverSection) {
				_isHeaderInverted = true
			}
		})

		if (_isHeaderInverted !== isHeaderInvertedRef) {
			setIsHeaderInverted(_isHeaderInverted)
		}
	}

	const openModal = props => {
		setModalData({
			...props,
			isVisible: true
		})
	}

	const closeModal = () => {
		setModalData({
			...modalData,
			isVisible: false
		})
	}

	const optionsQuery = useStaticQuery(graphql`
		query optionsQuery {
			siteData {
				options {
					options {
						defaultEventImage {
							mediaItemUrl
						}
						links {
							colour
							link {
							url
							title
							}
						}
						logos {
							standard {
							mediaItemUrl
							}
							inverted {
							mediaItemUrl
							}
						}
						sections {
							sectionId
							labels
						}
						socials {
							type
							url
						}
						footerLinks {
							title
							url
						}
					}
				}
			}
		}
	`)

	const { options } = optionsQuery.siteData.options

	return (
		<MainContext.Provider
			value={{
				options,
				openModal,
				closeModal,
				modalData,
				addInvertedHeaderSection,
				checkInvertedHeaderOffsets,
				isHeaderInverted,
				hasScrolled,
				componentRefs,
				isLoadingEvents,
				groupedEventData
			}}
		>
			{children}
		</MainContext.Provider>
	)
};

export default ({ element }) => (
	<Provider>
		{element}
	</Provider>
);