import React, { useContext, useState } from 'react'
import { MainContext } from "context/context"
import Link from "components/globals/link"
import Button from "components/globals/button"
import MobileMenu from 'components/globals/mobileMenu'
import { Link as ScrollLink } from "react-scroll"

import { useIsMobile } from 'utils'

export default () => {
	const {
		options,
	} = useContext(MainContext)

	const isMobile = useIsMobile()

	const [active, setActive] = useState(false)

	const toggleMenu = () => {
		active ? setActive(false) : setActive(true)
	}

	const scrollToActivitySheets = () => {
		const activitySheetsRow = document.getElementById('activitySheets')

		const pageOffset = (activitySheetsRow.getBoundingClientRect().top + window.scrollY - 100)

		window.scroll({
			behavior: 'smooth',
			left: 0,
			top: pageOffset
		})
	}

	return (
		<header>
			{ isMobile &&
				<MobileMenu
					open={active}
					onClick={toggleMenu}
				/>
			}
			<div className="header-top-row container">
				<Link to="/" className="header-logo">
					<img src={options.logos.inverted.mediaItemUrl} className="logo" alt="Playgroup-logo" />
				</Link>
				{isMobile ?
					<div
						className="mobile-menu-button"
						onClick={() => { toggleMenu() }}
					>
						{
							active ?
								<i className="fa fa-times" aria-hidden="true"></i>
								:
								<i className="fa fa-1x fa-bars" aria-hidden="true"></i>
						}
					</div>
					:
					<ul>
						{ options.links.map((optionLink, index) => {

							const {
								link,
								colour
							}= optionLink

							return (
								<li key={index}>
									{link.url.includes('http') ?
									<Link to={link.url}>
										<Button 
											label={link.title}
											backgroundColor={colour}
											to={link.url}
											fontColor={colour === "Yellow" ? "black" : "white"}
											compact
										/>
									</Link>
									:
									<ScrollLink
										key={ index }
										to={ link.url }
										spy={ true }
										smooth={ true }
										offset={ -100 }
										duration={ 1000 }
										style={{ cursor: `pointer` }}
									>
										<Button 
											label={link.title}
											backgroundColor={colour}
											to={link.url}
											fontColor={colour === "Yellow" ? "black" : "white"}
											compact
										/>
									</ScrollLink>
									}
								</li>
							)
						})}
					</ul>
				}
			</div>
			<div className={`header-bottom-row container ${active ? 'mobile-menu-active' : ''}`}>
				{
					options.sections.map((menuItem, index) => {
						return (
							<ScrollLink
								key={index}
								activeClass="active"
								to={menuItem.sectionId}
								smooth={true}
								offset={-150}
								duration={1000}
								style={{ cursor: `pointer` }}
								className={'header-link'}
							>
								{ menuItem.labels}
							</ScrollLink>
						)
					})
				}
			</div>
		</header>
	)
}