import React from 'react'

export default ({
    close
}) => {
    return (
        <div>
            <h3>
                Thanks for that!
            </h3>
            <div className={'copy'} dangerouslySetInnerHTML={{ __html: `<p>We've received your submission, and will be in touch as soon as we can.` }} />
            <button
                className='button outline'
                onClick={close}
            >
                Sounds good!
            </button>
        </div>
    )
}