import React, { useContext } from 'react'
import { MainContext } from "context/context"
import Link from "components/globals/link"

export default () => {

	const { 
		options
	} = useContext(MainContext)

	return (
		<footer>
			<img src={ options.logos.inverted.mediaItemUrl } className="footer-logo"/>
			<ul>
				{
					options.footerLinks.map((links, index) => {
						return (
							<li key={ index }>
								<Link to={ links.url }>
									{ links.title }
								</Link>
							</li>
						)
					})
				}
			</ul>
		</footer>
	)
}