import React, { useEffect, useRef, useContext } from 'react'
import Link from 'components/globals/link'
import { MainContext } from 'context/context'

export default ({
    label,
    url,
    onClick,
    type = "link",
    inverted,
    compact,
    icon,
    to,
    backgroundColor,
    fontColor
}) => {
    const elementRef = useRef(null)

    const buttonLabel = label || 'Read more'

    let className = 'button'

    if (inverted) {
        className += ' inverted'
    }

    if (!label) {
        className += ' icon-only'
    }

    if (compact) {
        className += ' compact'
    }

    if (!fontColor) {
        fontColor = "white"
    }

    if (!backgroundColor) {
        backgroundColor = "rgba(255, 255, 255, 0)"
    }

    switch (backgroundColor) {
        case "Yellow":
            backgroundColor = "#F4BE18"
            break;
        case "Blue":
            backgroundColor = "#6FABDD"
            break;
        case "Pink":
            backgroundColor = "#F27598"
            break;
        default:
            break;
    }

    const buttonContent = (
        <>
            <div className="button__filler"/>
            <span className="button__text">
                <span className="button__text-inner" style={{ color: fontColor }}>
                    { icon &&
                        <i className={`fe fe-${ icon }`} />
                    }
                    { buttonLabel }
                </span>
            </span>
        </>
    )

    const commonProps = {
        className,
        onClick,
        ref: ref => elementRef.current = ref
    }

    if (type === 'button' || !url) {
        return (
            <button
                {...commonProps}
                style={{ 
                    backgroundColor: backgroundColor,
                    border: `none`
                }}
            >
                {buttonContent}
            </button>
        )
    } else if (url.startsWith('https')) {
        return (
            <a
                href={url}
                {...commonProps}
                target={'_blank'}
                style={{ 
                    backgroundColor: backgroundColor,
                    border: `none`,
                    textDecoration: 'none'
                }}
            >
                {buttonContent}
            </a>
        )
    } else {
        return (
            <Link
                to={url}
                {...commonProps}
            >
                {buttonContent}
            </Link>
        )
    }
}
