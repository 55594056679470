import React, { useContext } from 'react'

import Header from './header'
import Footer from './footer'
import Modal from './modal'

import { MainContext } from "context/context"

export default ({ children, location }) => {
	const {
		options: {
			cookiesPopUp
		}
	} = useContext(MainContext)

	return (
		<div className="page-container">
			<Header location={location} />
			<main data-scroll-container>
				{children}
				<Footer />
			</main>
			<Modal />
		</div>
	)
}